import {email as validateEmail} from 'goodeggs-validators';

const CHANGE_FROM = 'GIFT_CARD_PURCHASE_CHANGE_FROM';
const CHANGE_FIRST_NAME = 'GIFT_CARD_PURCHASE_CHANGE_FIRST_NAME';
const CHANGE_LAST_NAME = 'GIFT_CARD_PURCHASE_CHANGE_LAST_NAME';
const CHANGE_EMAIL = 'GIFT_CARD_PURCHASE_CHANGE_EMAIL';
const VALIDATE_SENDER = 'GIFT_CARD_PURCHASE_VALIDATE_SENDER';

export const actions = {
  changeFrom: (from) => ({
    type: CHANGE_FROM,
    value: from,
  }),
  changeFirstName: (firstName) => ({
    type: CHANGE_FIRST_NAME,
    value: firstName,
  }),
  changeLastName: (lastName) => ({
    type: CHANGE_LAST_NAME,
    value: lastName,
  }),
  changeEmail: (email) => ({
    type: CHANGE_EMAIL,
    value: email,
  }),
  validateSender: () => ({
    type: VALIDATE_SENDER,
  }),
};

export function validate(state) {
  const errors = {};

  if (!state.firstName || state.firstName.trim() === '') {
    errors.firstName = 'Required';
  }
  if (!state.lastName || state.lastName.trim() === '') {
    errors.lastName = 'Required';
  }
  if (!state.email) {
    errors.email = 'Required';
  } else if (!validateEmail(state.email)) {
    errors.email = 'Invalid email';
  }
  if (!state.from || state.from.trim() === '') {
    errors.from = 'Required';
  }

  return errors;
}

export function reducer(state, action = {}) {
  let senderState = {...state};

  switch (action.type) {
    case CHANGE_FROM:
      return {...senderState, from: action.value};
    case CHANGE_FIRST_NAME:
      senderState = {
        ...senderState,
        firstName: action.value,
        from: [senderState.firstName, senderState.lastName].filter(Boolean).join(' '),
      };
      return senderState;
    case CHANGE_LAST_NAME:
      senderState = {
        ...senderState,
        lastName: action.value,
        from: [senderState.firstName, senderState.lastName].filter(Boolean).join(' '),
      };
      return senderState;
    case CHANGE_EMAIL:
      return {...senderState, email: action.value};
    case VALIDATE_SENDER:
      return {...senderState, errors: validate(senderState)};
    default:
      return senderState;
  }
}
