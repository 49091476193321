import React from 'react';
import classNames from 'classnames';

import {AppDispatch} from 'web/helpers/redux_client';

import {actions} from '../../ducks/sender_form';

interface SenderFormProps {
  from?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  hasUser?: boolean;
  errors?: {[key: string]: string | undefined};
  dispatch: AppDispatch;
}
export const SenderForm = (props: SenderFormProps): JSX.Element => {
  const handleFromChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.dispatch(actions.changeFrom(e.target.value));
  };

  const handleFirstNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.dispatch(actions.changeFirstName(e.target.value));
  };

  const handleLastNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.dispatch(actions.changeLastName(e.target.value));
  };

  const handleEmailChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.dispatch(actions.changeEmail(e.target.value));
  };

  const renderLoggedInSenderForm = (): JSX.Element => {
    return (
      <div className="sender-form">
        <input
          className={classNames(
            'form-control',
            'gift-card-purchase-page__input',
            'gift-card-purchase-page__input-sender-from',
            {error: props.errors?.from},
          )}
          type="text"
          placeholder="Name or names"
          value={props.from}
          onChange={(e) => handleFromChanged(e)}
        />
        {props.errors?.from != null ? <div className="error"> {props.errors?.from}</div> : null}
      </div>
    );
  };

  const renderLoggedOutSenderForm = (): JSX.Element => {
    return (
      <div className="sender-form">
        <div className="gift-card-purchase-page__split-column">
          <input
            className={classNames(
              'form-control',
              'gift-card-purchase-page__input',
              'gift-card-purchase-page__input-sender-firstName',
              {error: props.errors?.firstName},
            )}
            type="text"
            placeholder="First Name"
            value={props.firstName}
            onChange={(e) => handleFirstNameChanged(e)}
          />
          {props.errors?.firstName != null ? (
            <div className="error">{props.errors?.firstName}</div>
          ) : null}
        </div>
        <div className="gift-card-purchase-page__split-column">
          <input
            className={classNames(
              'form-control',
              'gift-card-purchase-page__input',
              'gift-card-purchase-page__input-sender-lastName',
              {error: props.errors?.lastName},
            )}
            type="text"
            placeholder="Last Name"
            value={props.lastName}
            onChange={(e) => handleLastNameChanged(e)}
          />
          {props.errors?.lastName != null ? (
            <div className="error">{props.errors?.lastName}</div>
          ) : null}
        </div>
        <div>
          <input
            className={classNames(
              'form-control',
              'gift-card-purchase-page__input',
              'gift-card-purchase-page__input-sender-email',
              {error: props.errors?.email},
            )}
            type="text"
            placeholder="Your Email"
            value={props.email}
            onChange={(e) => handleEmailChanged(e)}
          />
          {props.errors?.email != null ? <div className="error">{props.errors?.email}</div> : null}
        </div>
      </div>
    );
  };

  return props.hasUser ? renderLoggedInSenderForm() : renderLoggedOutSenderForm();
};
