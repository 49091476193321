import {postJson} from 'web/helpers/api_helpers';

// TODO(@shermam) This type should probably not live here
// this is the TS interface for the Gift Card model declare here:
// src/domain/gift_cards/models/gift_card.js
// I am holding on to tsifying that model right now since it has
// a bunch of mongoose methods that would required a pretty extensive update to refactor
// Consider moving this somewhere else
export interface GiftCard {
  _id: string;
  buyer: string;
  code: string;
  recipientName?: string;
  recipientEmail?: string;
  message?: string;
  fromName?: string;
  fromEmail?: string;
  amount: number;
  payments?: Array<{
    _id: string;
    source: 'stripe' | 'goodEggsPromo' | 'userStoreCredit';
    amount: number;
    createdAt: Date;
    refunds: Array<{
      _id: string;
      amount: number;
      createdAt: Date;
      refundId?: string;
    }>;
    data: Record<string, unknown>;
  }>;
  stripe: {
    declineReason: string;
    charge: {
      id: string;
    };
  };
  redeemedBy?: string;
  redeemedAt?: Date;
  deliveryMethod?: 'email' | 'print';
  status?: 'initializing' | 'paid' | 'redeemed' | 'cancelled' | 'declined';
}

export async function placeOrder({
  amount,
  deliveryMethod,
  fromName,
  fromEmail,
  recipientEmail,
  recipientName,
  message,
}: {
  amount: number | undefined;
  deliveryMethod: string;
  fromName: string;
  fromEmail: string | undefined;
  message: string;
  recipientName: string;
  recipientEmail?: string;
}): Promise<GiftCard> {
  return postJson('/api/gift-cards/purchase', {
    body: {
      amount,
      deliveryMethod,
      fromName,
      fromEmail,
      recipientName,
      recipientEmail,
      message,
    },
  });
}
