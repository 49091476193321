import {Helmet} from 'react-helmet-async';
import {useDispatch, useSelector} from 'react-redux';
import React, {FC, SyntheticEvent, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {isEmpty} from 'lodash';

import ActiveCreditCard from 'web/components/active_credit_card';
import MarketLayout, {reducer as marketLayoutReducer} from 'web/components/market_layout';
import ChoiceSelect from 'web/components/choice_select';
import SubmitButton from 'web/components/submit_button';
import CreditCardForm from 'web/components/stripe_credit_card_form';
import Alert from 'web/components/alert';
import assetPath from 'web/helpers/asset_path';
import {AppDispatch, PageType} from 'web/helpers/redux_client';
import StripeContext from 'web/components/stripe_credit_card_form/stripe_context';

import {actions, reducer as purchasePageReducer} from './ducks';
import {StoreData} from './controller';
import {SenderForm} from './components/sender_form';

export interface TwoColumnRecipientDetailsProps {
  purchaseDetails: NonNullable<StoreData['purchaseDetails']>;
  handleRecipientNameChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRecipientEmailChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TwoColumnRecipientDetails: FC<TwoColumnRecipientDetailsProps> = (props) => {
  return (
    <div className="gift-card-purchase-page__control-column">
      <div className="gift-card-purchase-page__split-column">
        <input
          className={classNames(
            'form-control',
            'gift-card-purchase-page__input',
            'gift-card-purchase-page__input-recipient-name',
            {
              error: props.purchaseDetails.errors.recipientName,
            },
          )}
          type="text"
          placeholder="Recipient Name"
          value={props.purchaseDetails.recipientName || ''}
          onChange={props.handleRecipientNameChanged}
        />
        {props.purchaseDetails.errors.recipientName != null &&
        props.purchaseDetails.errors.recipientName !== '' ? (
          <div className="error">{props.purchaseDetails.errors.recipientName as string}</div>
        ) : null}
      </div>
      <div className="gift-card-purchase-page__split-column">
        <input
          className={classNames(
            'form-control',
            'gift-card-purchase-page__input',
            'gift-card-purchase-page__input-recipient-email',
            {
              error: props.purchaseDetails.errors.recipientEmail,
            },
          )}
          type="text"
          placeholder="Recipient Email"
          value={props.purchaseDetails.recipientEmail || ''}
          onChange={props.handleRecipientEmailChanged}
        />
        {props.purchaseDetails.errors.recipientEmail != null &&
        props.purchaseDetails.errors.recipientEmail !== '' ? (
          <div className="error">{props.purchaseDetails.errors.recipientEmail as string}</div>
        ) : null}
      </div>
    </div>
  );
};

export interface SingleColumnRecipientNameProps {
  purchaseDetails: StoreData['purchaseDetails'];
  handleRecipientNameChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SingleColumnRecipientName: FC<SingleColumnRecipientNameProps> = (props) => {
  return (
    <div className="gift-card-purchase-page__control-column">
      <input
        className={classNames(
          'form-control',
          'gift-card-purchase-page__input',
          'gift-card-purchase-page__input-recipient-name',
          {
            error: props.purchaseDetails?.errors.recipientName,
          },
        )}
        type="text"
        placeholder="Recipient Name"
        value={props.purchaseDetails?.recipientName || ''}
        onChange={props.handleRecipientNameChanged}
      />
      {props.purchaseDetails?.errors.recipientName != null &&
      props.purchaseDetails?.errors.recipientName !== '' ? (
        <div className="error">{props.purchaseDetails.errors.recipientName as string}</div>
      ) : null}
    </div>
  );
};

const GiftCardPage: PageType<StoreData> = () => {
  const globalErrorRef = useRef<HTMLDivElement>(null);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (globalErrorRef.current) {
      window.scrollTo(0, globalErrorRef.current.offsetTop - 80); // offset by header
    }
  }, []);

  const state = useSelector((s: StoreData) => {
    const captchaToken = s.creditCardForm?.captchaToken;

    const showActiveCard = s.hasActiveCard && !s.showCreditCardForm;
    const submitButtonDisabled = s.showCaptcha && !showActiveCard && captchaToken == null;

    return {
      ...s,
      showActiveCard,
      submitButtonDisabled,
    };
  });

  let alertComponent = null;
  if (state.globalError) {
    switch (state.globalError.type) {
      case 'PAYMENT_ERROR':
      case 'validation_error':
        /* validation error come directly from stripe elements which is why it's lowercase */
        alertComponent = (
          <Alert type="error" heading="Sorry, there was a problem with your payment.">
            {state.globalError.message}
          </Alert>
        );
        break;
      case 'EMAIL_USED':
        alertComponent = (
          <Alert type="error" heading="Email address is already registered.">
            Please <a href="/signin?returnTo=/giftcards">sign in</a>.
          </Alert>
        );
        break;
      default:
        alertComponent = (
          <Alert
            type="error"
            heading={state.globalError.customerMessage || 'Oops, something went wrong.'}
          />
        );
    }
  }

  const handleOrderPlaceClicked = (e: SyntheticEvent): void => {
    e.preventDefault();
    const cardElement = elements?.getElement(CardElement);
    dispatch(actions.initiateOrder(stripe, cardElement));
  };

  const handleGiftAmountSelected = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    dispatch(actions.selectGiftAmount(Number(e.target.value)));
  };

  const handleDeliveryMethodSelected = (method: string): void => {
    dispatch(actions.selectDeliveryMethod(method));
  };

  const handleRecipientNameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.changeRecipientName(e.target.value));
  };

  const handleRecipientEmailChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(actions.changeRecipientEmail(e.target.value));
  };

  const handleGiftMessageChanged = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    dispatch(actions.changeGiftMessage(e.target.value));
  };

  const handleShowCreditCardForm = (): void => {
    dispatch(actions.showCreditCardForm());
  };

  return (
    <MarketLayout disableBasketDropdown disableUpcomingOrdersBanner>
      <Helmet>
        <title>Gift Cards | Good Eggs</title>
        <meta property="og:title" content="Give the gift of local food! | Good Eggs" />
        <meta
          property="og:description"
          content="Share a gift card with the good eggs in your life. Gift cards can be sent via email or printed at home."
        />
        <meta
          property="og:image"
          content={assetPath.assetPathWithProtocol(
            '/img/jujube/gift_cards/gift-card-landing-page.jpg',
          )}
        />
        <script src="https://js.stripe.com/v3/" />
        <script src="https://www.google.com/recaptcha/api.js" />
      </Helmet>

      <div className="gift-card-purchase-page">
        <div className="gift-card-purchase-page__sign-in-container">
          {state.user ? null : (
            <div className="gift-card-purchase-page__sign-in">
              Returning Customer? <a href="/signin?returnTo=/giftcards">Sign In</a>
            </div>
          )}
        </div>
        <form className="gift-card-purchase-page__card" onSubmit={handleOrderPlaceClicked}>
          <img
            className="gift-card-purchase-page__card-image"
            src={`${assetPath('/img/jujube/gift_cards/gift-card-landing-page.jpg')}?auto=format`}
            alt="Gift Card"
          />
          <h1 className="gift-card-purchase-page__header">Give the gift of local food!</h1>
          <h2 className="gift-card-purchase-page__subtext">
            Share a gift card with the good eggs in your life. Gift cards can be sent via email or
            printed at home.
          </h2>
          <hr className="gift-card-purchase-page__divider" />
          {alertComponent ? (
            <div className="gift-card-purchase-page__global-error" ref={globalErrorRef}>
              {alertComponent}
            </div>
          ) : null}
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__centered-label-column">Gift Amount</div>
            <div className="gift-card-purchase-page__control-column">
              <ChoiceSelect
                value={state.purchaseDetails?.amount ?? ''}
                onChange={handleGiftAmountSelected}
              >
                {state.purchaseDetails?.amounts?.map((option) => (
                  <option key={option} value={option}>
                    {`$${option}`}
                  </option>
                ))}
              </ChoiceSelect>
            </div>
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__label-column">Delivery Method</div>
            <div className="gift-card-purchase-page__control-column">
              <div className="gift-card-purchase-page__delivery-method">
                <input
                  id="deliveryMethodEmail"
                  type="radio"
                  onChange={() => handleDeliveryMethodSelected('email')}
                  checked={state.purchaseDetails?.deliveryMethod === 'email'}
                />
                <label htmlFor="deliveryMethodEmail">
                  {' '}
                  Email - send a digital card directly to recipient{' '}
                </label>
              </div>
              <div className="gift-card-purchase-page__delivery-method">
                <input
                  id="deliveryMethodPrint"
                  type="radio"
                  onChange={() => handleDeliveryMethodSelected('print')}
                  checked={state.purchaseDetails?.deliveryMethod === 'print'}
                />
                <label htmlFor="deliveryMethodPrint">
                  {' '}
                  Print at home - provide me a printable gift card{' '}
                </label>
              </div>
            </div>
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__centered-label-column">* From</div>
            <div className="gift-card-purchase-page__control-column">
              <SenderForm dispatch={dispatch} {...state.sender} />
            </div>
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__centered-label-column">* Recipient</div>
            {state.purchaseDetails?.deliveryMethod === 'email' ? (
              <TwoColumnRecipientDetails
                purchaseDetails={state.purchaseDetails}
                handleRecipientEmailChanged={handleRecipientEmailChanged}
                handleRecipientNameChanged={handleRecipientNameChanged}
              />
            ) : (
              <SingleColumnRecipientName
                purchaseDetails={state.purchaseDetails}
                handleRecipientNameChanged={handleRecipientNameChanged}
              />
            )}
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__centered-label-column">Message</div>
            <div className="gift-card-purchase-page__control-column">
              <textarea
                className="form-control gift-card-purchase-page__input"
                placeholder="Gift Message (optional)"
                value={state.purchaseDetails?.giftMessage || ''}
                onChange={handleGiftMessageChanged}
              />
            </div>
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__label-column">* Payment</div>
            <div className="gift-card-purchase-page__control-column">
              {!state.showActiveCard ||
              state.user?.active_card == null ||
              !isEmpty(state.user?.active_card) ? (
                <CreditCardForm
                  showButtons={false}
                  showError={false}
                  showCaptcha={state.showCaptcha}
                  recaptchaSitekey={state.recaptchaSitekey}
                />
              ) : (
                <span className="credit-card-form__credit-card-info">
                  <ActiveCreditCard activeCard={state.user.active_card} /> (
                  <span
                    onClick={handleShowCreditCardForm}
                    className="credit-card-form__credit-card-change"
                  >
                    change
                  </span>
                  )
                </span>
              )}
            </div>
          </div>
          <div className="gift-card-purchase-page__row">
            <div className="gift-card-purchase-page__label-column" />
            <div className="gift-card-purchase-page__control-column">
              <SubmitButton
                className="button gift-card-purchase-page__purchase"
                isSaving={state.purchaseDetails?.saving}
                workingLabel="processing"
                onClick={handleOrderPlaceClicked}
                disabled={state.submitButtonDisabled}
              >
                Place Order
              </SubmitButton>
            </div>
          </div>
        </form>
      </div>
    </MarketLayout>
  );
};

GiftCardPage.pageName = 'Gift Cards';
GiftCardPage.reducer = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  const newState = marketLayoutReducer(state, action);
  return purchasePageReducer(newState, action);
};

const Wrapper: PageType<StoreData> = () => {
  return (
    <StripeContext>
      <GiftCardPage />
    </StripeContext>
  );
};

Wrapper.pageName = GiftCardPage.pageName;
Wrapper.reducer = GiftCardPage.reducer;

export default Wrapper;
