import {email as validateEmail} from 'goodeggs-validators';
import {AnyAction, Reducer} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {Stripe, StripeCardElement} from '@stripe/stripe-js';

import {
  actions as setupIntentFormActions,
  reducer as reduceCreditCardForm,
  STRIPE_SETUP_TYPE as setupIntentEnum,
} from 'web/components/stripe_credit_card_form/duck';
import {postUser} from 'web/helpers/user_duck/api_client';
import {ISerializedUser} from 'domain/users';

import {placeOrder} from '../helpers/api_client';
import {actions as senderFormActions, reducer as reduceSender} from './sender_form';
import {StoreData} from '../controller';

const SELECT_GIFT_AMOUNT = 'GIFT_CARD_PURCHASE_SELECT_GIFT_AMOUNT';
const SELECT_DELIVERY_METHOD = 'GIFT_CARD_PURCHASE_SELECT_DELIVERY_METHOD';
const CHANGE_RECIPIENT_NAME = 'GIFT_CARD_PURCHASE_CHANGE_RECIPIENT_NAME';
const CHANGE_RECIPIENT_EMAIL = 'GIFT_CARD_PURCHASE_CHANGE_RECIPIENT_EMAIL';
const CHANGE_GIFT_MESSAGE = 'GIFT_CARD_PURCHASE_CHANGE_GIFT_MESSAGE';
const SHOW_CREDIT_CARD_FORM = 'GIFT_CARD_PURCHASE_SHOW_CREDIT_CARD_FORM';
const START_SAVING = 'GIFT_CARD_PURCHASE_START_SAVING';
const FINISH_SAVING_WITH_ERROR = 'GIFT_CARD_PURCHASE_FINISH_SAVING';
const VALIDATE_PURCHASE_DETAILS = 'GIFT_CARD_PURCHASE_VALIDATE';
const FINISH_REGISTER_USER = 'GIFT_CARD_PURCHASE_REGISTER_USER';

interface InternalError {
  type?: string | null;
  message?: string | null;
}

export type PurchaseDetails = NonNullable<StoreData['purchaseDetails']>;
export type Sender = NonNullable<StoreData['sender']>;

function validatePurchaseDetails(purchaseDetails: PurchaseDetails): Record<string, string> {
  let purchaseDetailsErrors: Record<string, string> = {};
  const requiredFields: Array<keyof PurchaseDetails> = ['recipientName'];
  if (purchaseDetails.deliveryMethod === 'email') {
    requiredFields.push('recipientEmail');
  }

  purchaseDetailsErrors = requiredFields.reduce<Record<string, string>>((memo, field) => {
    if (purchaseDetails[field] == null || purchaseDetails[field]?.toString().trim() === '') {
      memo[field] = 'Required';
    }
    return memo;
  }, {});

  if (!purchaseDetailsErrors.recipientEmail && purchaseDetails.deliveryMethod === 'email') {
    if (!validateEmail(purchaseDetails.recipientEmail)) {
      purchaseDetailsErrors.recipientEmail = 'Invalid email';
    }
  }

  return purchaseDetailsErrors;
}

async function registerUser(sender: Sender): Promise<ISerializedUser | null> {
  if (sender.hasUser) {
    return Promise.resolve(null);
  }
  return postUser(sender);
}

export const actions = {
  selectGiftAmount: (amount: number) => ({
    type: SELECT_GIFT_AMOUNT,
    value: amount,
  }),
  selectDeliveryMethod: (method: string) => ({
    type: SELECT_DELIVERY_METHOD,
    value: method,
  }),
  changeRecipientName: (name: string) => ({
    type: CHANGE_RECIPIENT_NAME,
    value: name,
  }),
  changeRecipientEmail: (email: string) => ({
    type: CHANGE_RECIPIENT_EMAIL,
    value: email,
  }),
  changeGiftMessage: (message: string) => ({
    type: CHANGE_GIFT_MESSAGE,
    value: message,
  }),
  showCreditCardForm: () => ({
    type: SHOW_CREDIT_CARD_FORM,
  }),
  validatePurchaseDetails: () => ({
    type: VALIDATE_PURCHASE_DETAILS,
  }),
  startSaving: () => ({
    type: START_SAVING,
  }),
  finishSavingWithError: (error: InternalError) => ({
    type: FINISH_SAVING_WITH_ERROR,
    value: error,
  }),
  finishRegisterUser: (userData: ISerializedUser | null) => ({
    type: FINISH_REGISTER_USER,
    value: userData,
  }),
  initiateOrder:
    (stripe?: Stripe | null, cardElement?: StripeCardElement | null) =>
    async (dispatch: ThunkDispatch<StoreData, null, AnyAction>, getState: () => StoreData) => {
      dispatch(actions.validatePurchaseDetails());
      dispatch(senderFormActions.validateSender());

      const {purchaseDetails, sender, creditCardForm} = getState();

      if (!purchaseDetails || !sender || !creditCardForm) return;

      if (Object.keys(purchaseDetails.errors).length > 0 || Object.keys(sender.errors).length > 0) {
        return;
      }

      dispatch(actions.startSaving());

      try {
        const userData = await registerUser(sender);

        if (userData) {
          dispatch(actions.finishRegisterUser(userData));
        }

        if (stripe && cardElement != null) {
          dispatch(
            setupIntentFormActions.createSetupIntent((setupIntentId) => {
              dispatch(
                setupIntentFormActions.confirmSetupIntent(
                  stripe,
                  cardElement,
                  setupIntentId,
                  creditCardForm.captchaToken,
                  async () => dispatch(actions.finalizeOrder()),
                ),
              );
            }),
          );
        } else {
          dispatch(actions.finalizeOrder());
        }
      } catch (err) {
        if (err == null || typeof err !== 'object' || !('type' in err)) {
          throw err;
        }

        const error = {
          type: (err as InternalError).type || 'UNKNOWN',
          message: (err as InternalError).message || 'Please call community care.',
        };

        dispatch(actions.finishSavingWithError(error));
      }
    },
  finalizeOrder:
    () =>
    async (dispatch: ThunkDispatch<StoreData, null, AnyAction>, getState: () => StoreData) => {
      const {purchaseDetails, sender} = getState();

      if (!purchaseDetails || !sender) return;

      try {
        const giftCard = await placeOrder({
          amount: purchaseDetails.amount,
          deliveryMethod: purchaseDetails.deliveryMethod,
          fromName: sender.from,
          fromEmail: sender.email,
          message: purchaseDetails.giftMessage,
          recipientName: purchaseDetails.recipientName,
          recipientEmail:
            purchaseDetails.deliveryMethod === 'email' ? purchaseDetails.recipientEmail : undefined,
        });

        // TODO: dispatch a navigate action
        window.location.href = `/giftcards/thank-you?giftCardId=${giftCard._id}`;
      } catch (err) {
        if (err == null || typeof err !== 'object' || !('type' in err)) {
          throw err;
        }

        const error = {
          type: (err as InternalError).type || 'UNKNOWN',
          message: (err as InternalError).message || 'Please call community care.',
        };

        dispatch(actions.finishSavingWithError(error));
      }
    },
};

const DEFAULT_PURCHASE_DETAILS = {
  deliveryMethod: 'email',
  recipientName: '',
  recipientEmail: '',
  giftMessage: '',
  errors: {},
  saving: false,
};

const reducePurchaseDetails = (
  purchaseDetails: PurchaseDetails,
  action: AnyAction,
): PurchaseDetails => {
  switch (action.type) {
    case SELECT_GIFT_AMOUNT:
      return {...purchaseDetails, amount: action.value};
    case SELECT_DELIVERY_METHOD:
      return {...purchaseDetails, deliveryMethod: action.value};
    case CHANGE_RECIPIENT_NAME:
      return {...purchaseDetails, recipientName: action.value};
    case CHANGE_RECIPIENT_EMAIL:
      return {...purchaseDetails, recipientEmail: action.value};
    case CHANGE_GIFT_MESSAGE:
      return {...purchaseDetails, giftMessage: action.value};
    case VALIDATE_PURCHASE_DETAILS:
      return {...purchaseDetails, errors: validatePurchaseDetails(purchaseDetails)};
    case START_SAVING:
      return {...purchaseDetails, errors: {}, saving: true};
    case FINISH_SAVING_WITH_ERROR:
      return {...purchaseDetails, saving: false};
    default:
      return purchaseDetails;
  }
};

const reduceGlobalError: Reducer<StoreData['globalError']> = (globalError, action) => {
  switch (action.type) {
    case START_SAVING:
      return null;
    case FINISH_SAVING_WITH_ERROR:
      return action.value;
    case setupIntentEnum.SETUP_INTENT_ERROR:
      return action.error;
    default:
      return globalError;
  }
};

function defaultSenderState(user?: StoreData['user']): Sender {
  if (user) {
    return {
      from: user.firstName,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      hasUser: true,
      errors: {},
    };
  }
  return {
    from: '',
    email: '',
    hasUser: false,
    errors: {},
  };
}

function defaultPurchaseDetails(giftAmount?: StoreData['giftAmount']): PurchaseDetails {
  return {
    ...DEFAULT_PURCHASE_DETAILS,
    amount: giftAmount?.default,
    amounts: giftAmount?.options,
  };
}

export const reducer: Reducer<StoreData> = (state, action) => {
  // TODO: (@shermam) This is not ideal, but the state should always be set here
  // since we preload it from the controller. We should maybe come up with a better
  // way of asserting the type here
  if (!state) throw new Error('State should always be preloaded here');

  const senderState = state.sender || defaultSenderState(state.user);
  const purchaseDetails = state.purchaseDetails || defaultPurchaseDetails(state.giftAmount);

  const newState: StoreData = {
    ...state,
    purchaseDetails: reducePurchaseDetails(purchaseDetails, action),
    globalError: reduceGlobalError(state.globalError, action),
    sender: reduceSender(senderState, action),
    creditCardForm: reduceCreditCardForm(state.creditCardForm, action),
  };

  switch (action.type) {
    case SHOW_CREDIT_CARD_FORM:
      return {...newState, showCreditCardForm: true};
    case FINISH_REGISTER_USER:
      return {...newState, user: action.value, sender: defaultSenderState(action.value)};
  }

  return newState;
};
